import { defineStore } from 'pinia'
 
const useStore = defineStore('userInfo',{
    state:()=>{
        return {
            userInfo:null,
            isLogin:false,
        }
    },
    getters:{

    },
    actions:{
        setLogin(isLogin){
            this.isLogin = isLogin;
        },
        setUserInfo(userInfo){
            this.userInfo = userInfo;
        },
        clearUserInfo(){
            this.userInfo = null;
        }
    },
    // 开启持久化
    persist:{
        enabled: true, // 开始数据缓存
        strategies:[
            {
                // storage: LocalStorage, // 默认是sessionStorage
                paths:['userInfo'],
            }
        ]
    }
})

export default useStore