import http from '../utils/http'
// 
/**
 *  @parms resquest 请求地址 例如：http://197.82.15.15:8088/request/...
 *  @param '/testIp'代表vue-cil中config，index.js中配置的代理
 */
let resquest = ""
 
// // get请求
// export function getListAPI(params){
//     return http.get(`${resquest}/getList.json`,params)
// }
// // post请求
// export function postFormAPI(params){
//     return http.post(`${resquest}/postForm.json`,params)
// }
// // put 请求
// export function putSomeAPI(params){
//     return http.put(`${resquest}/putSome.json`,params)
// }
// // delete 请求
// export function deleteListAPI(params){
//     return http.delete(`${resquest}/deleteList.json`,params)
// }
 
// 登录
export function LoginApi(params){
    return http.post('app/user/login',params)
}

// 发送验证码
export function SendCodeApi(params){
    return http.post('app/user/sendSms',params)
}

// 海鲜生大屏接口
export function DeviceInfoApi(params){
    return http.post('/show/user/largescreen/query/statistics',params)
}