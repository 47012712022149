// import { createMemoryHistory, createRouter } from 'vue-router'
import Router from 'vue-router';
import Vue from 'vue';
import useStore from '../store/user.js'

Vue.use(Router);
import HomeView from '../views/HomeView'
import LoginView from '../views/LoginView'

const router = new Router({
    mode: 'history',
    routes: [
      {
        path: '/home',
        name: 'home',
        component: HomeView
      },
      {
        path: '/login',
        name: 'login',
        component: LoginView
      },
    ]
  })

  // 注册全局导航守卫
router.beforeEach((to, from, next) => {
  document.title = '海都';
  let store = useStore()
  let userInfo = store.userInfo; // 假设用户没有登录 
  if (to.name == 'home') { // 判断用户是否要进入
    if (userInfo) { // 如果用户已经登录，则直接进入
      next() // 放行
    } else { // 如果用户没有登录
      next({ name: 'login' }) // 跳转到登录页
    }
  } else if(to.path == '/'){
    if (userInfo) { // 如果用户已经登录，则直接进入
      next({ name: 'home' }) // 跳转到首页
    } else{
      next({ name: 'login' }) // 跳转到登录页
    }
  }
   else { // 如果用户不是要进入
    next() // 放行
  }
})

  export default router

