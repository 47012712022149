<template>
  <div class="content">
    <router-view></router-view>
 </div>  
</template>

<script>

export default {
  created(){
  }
}

</script>

<style>
</style>
