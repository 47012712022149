import Vue from 'vue'
import App from './App.vue'
// dataV
import dataV from '@jiaminghi/data-view'
// 路由
import router from './router';
// 持久化
import { createPinia } from 'pinia'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
// 公共样式
import '@/assets/css/common.css'
// 第三方UI组件
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

// 自适应
import './utils/flexible.js'

Vue.use(ElementUI);

const pinia = createPinia()
pinia.use(piniaPluginPersistedstate)

Vue.config.productionTip = false
Vue.use(dataV)
Vue.use(pinia)

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
